@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary_large {
    @apply w-[402px] h-[60px] py-[16px] rounded-[10px] text-sk-default2 text-body1-semibold bg-sk-sub text-center;
  }
  .btn-primary_small {
    @apply px-[12px] py-[4px] rounded-[4px] text-sk-default2 text-body3-medium bg-sk-sub text-center;
  }
  .btn-primary_mini {
    @apply w-[30px] h-[30px] flex items-center justify-center rounded-[4px] text-sk-default2 text-body3-medium bg-sk-sub text-center;
  }
}
